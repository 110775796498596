import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Table, Alert, Pagination, Space } from 'antd'
import { getDataOfType } from 'src/lib/utils/get-data-of-type'
import B2BLayout from 'src/react-app/layouts/b2b'
import fetchAPI from 'src/lib/utils/fetch-api'
import { calcTotalWidth, tableRowClick } from 'src/lib/utils/list'
import { TableWrapper, PaginationWrapper } from './styles'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import OrderFilter from './orders-filter'
import './index.css'

 const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)
const createViewed = createSelector(
  (state: any) => state.viewed,
  counts => counts
)

function getStatusTask(stageCode, date) {
	if (stageCode === 'done') {
    return 'done'
  }
  const currentDate = Date.now()
  if (new Date(date).valueOf() > currentDate) {
    return 'not-expired-and-not-done'
  }
  return 'expired-and-not-done'
}

function getTextByStatusTask(stageCode, date) {
  const status = getStatusTask(stageCode, date)
  if (status === 'done')
    return 'Завершено'
  if (status === 'not-expired-and-not-done')
    return 'Запланировано'
  return 'Просрочено'
}

const name = 'call-log'

export default function AdminObjectsListPage() {
  const history = useHistory()
  const location = useLocation()
  const orderFiltersRef = useRef<any>({})
  const [dataSource, setDataSource] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const [page, setPage] = useState<number>(1)
  const { sessionData } = useSelector(createSession)
  const userId = getDataOfType(sessionData, 'user.id', Number, 0)
  const initialFilter = {
    status: 'not-expired-and-not-done',
    users: userId,
    access_key: 'axioma'
  }
  const { counts } = useSelector(createViewed)
  const breadcrumbs = useMemo(() => (
    [{
      href: '/',
      title: 'Контактный центр',
    }, {
      href: location.pathname,
      title: 'Список задач',
    }]
  ), [location.pathname])

  const addDataColumns = useMemo(() => {
    const addDataColumns: any[] = [
      {
        title: 'ID',
        width: 100,
        dataIndex: 'parent-id',
        key: 'parent-id',
        render: (_, item) => getDataOfType(item, 'parent-id', String, '')
      },
      {
        title: 'Стадия',
        width: 150,
        dataIndex: 'stage-code',
        key: 'stage-code',
        render: (key, item) => getTextByStatusTask(key, item?.['finish-by'])
      },
      {
        title: 'Тип',
        width: 150,
        dataIndex: 'workflow-name',
        key: 'workflow-name',
        render: key => key || ''
      },
      {
        title: 'Комментарий',
        width: 150,
        dataIndex: 'data',
        key: 'data-comment',
        render: val => val?.attributes?.['comment'] || ''
      },
      {
        title: 'Клиент',
        width: 200,
        dataIndex: 'client',
        key: 'client',
        render: (_key, item) => (<>
        {item?.['client']?.['name'] || item?.['client-order']?.['name'] || ''}
        <br />
        {item?.['client']?.['login'] || item?.['client-order']?.['phone'] || ''}
        </>)
      },

      {
        title: 'Дата',
        width: 150,
        dataIndex: 'created-at',
        key: 'created-at',
        render: (key, item) => (<>
          {'Дата создания:'}
          <br />
          {item?.['created-at'] && dayjs(item?.['created-at']).format('DD.MM.YYYY hh:mm')}
          <br />
          {'Плановая дата:'}
          <br />
          {item?.['finish-by'] && dayjs(item?.['finish-by']).format('DD.MM.YYYY hh:mm')}
          <br />
          {'Дата завершения:'}
          <br />
          {item?.['data']?.['attributes']?.['date_completion'] && dayjs(item?.['data']?.['attributes']?.['date_completion']).format('DD.MM.YYYY hh:mm')}
        </>)
      },
      {
        title: 'Причина завершения',
        width: 200,
        dataIndex: 'comment-done',
        key: 'comment-done',
        render: (key, item) => item?.['data']?.['attributes']?.['comment-done'] || ''
      },
      {
        title: 'Автор',
        width: 200,
        dataIndex: 'author',
        key: 'author',
        render: (key, item) => item?.['author']?.['profile_data']?.['name'] || item?.['author']?.['login'] || ''
      },
      {
        title: 'Ответственные',
        width: 200,
        dataIndex: 'responsibles',
        key: 'responsibles',
        render: (key, item) => item?.responsibles?.map?.(item => item?.['profile_data']?.['name'] || item['login'])?.join?.(', ') || ''
      },

    ]
    return addDataColumns
  }, []) 

  const totalWidth: any = useMemo(() => calcTotalWidth( addDataColumns), [ addDataColumns])

  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(addDataColumns).filter(item => item.title), [addDataColumns])
  const cbTableRowClick = useCallback(item => {
    if (item['order-id']) {
      history.push(`/order/${item['order-id']}/edit`)
      return
    }
    if (item['parent-id']) {
      history.push(`/case/${item['parent-id']}/edit`)
    }
  }, [history])

  const deleteRecord = useCallback(async (currentId) => {
    try{
      await fetchAPI(`/api/collections/objects/${currentId}`, { method: 'DELETE'})
      setDataSource(dataSource => dataSource.filter(({ id }) => id !== currentId))
    }catch(error){
      console.error(error)
    }
  }, [])

  const getData = useCallback(async (value?:object, addUrlParams?:object) => {
    const urlParams = window.location.search.length
      ? Object.fromEntries(new URLSearchParams(window.location.search))
      : initialFilter

    const params = new URLSearchParams(urlParams).toString()

    const pageErrors: string[] = []
    const [
      metadata,
      collections
    ] = await Promise.all([
      fetchAPI(`/api/collections/${name}/metadata`),
      fetchAPI(`/api/web-hooks/get-tasks-list-by-orders?${params}`)
    ])
    const collectionsResultData = getDataOfType(collections, 'data.data', Array, [])
  
    const isMetadata = getDataOfType(metadata, 'data', Object, null)
    if(isMetadata === null) {
      pageErrors.push('Некорректный ответ сервера при получении метаданных коллекции')
    }
    const dataSource: any[] = collectionsResultData
    const newDataSource = dataSource.map((item) => ({
      ...item,
      deleteRecord,
    }))
    const totalRows = getDataOfType(collections, 'data.total-rows', Number, 0)
    setTotalRows(totalRows)
    setDataSource(newDataSource)
    setPageErrors(pageErrors)
    const curPage = Object.fromEntries( new URLSearchParams(window.location.search) )?.page
    setPage(curPage ? +curPage : 1)
    setIsDataLoading(false)
  }, [deleteRecord, setPage])

  const onPagination = useCallback(async ( page, pageSize) => {
    setPage(page)
    const urlParams = {page}
    setIsDataLoading(true)
    const currentSearch = Object.fromEntries( new URLSearchParams(window.location.search) )
    history.push({
      search: objectToUrl({...currentSearch, ...urlParams})
    })
    await getData()
    setIsDataLoading(false)
  }, [getData])

  useEffect(() => {
    getData()
  }, [ sessionData ])

  const rowClassName = useCallback(record => {
    const date = record['finish-by'] 
    const stageCode = record['stage-code']
    const status = getStatusTask(stageCode, date)
    return `table-row-color-${status}`
  }, [])

  const objectToUrl = (obj) => {
    const searchParams = new URLSearchParams()
    for (const key of Object.keys(obj)) {
      const value = obj[key]
      if( !value ) continue
      if (Array.isArray(value)) {
        for (const item of value) {
          searchParams.append(key, item)
        }
      } else {
        searchParams.append(key, value.toString())
      }
    }
    return searchParams.toString()
  }

  const onOrderFilter = useCallback(async value => {
    const currentSearch = Object.fromEntries( new URLSearchParams(window.location.search) )
    history.push({
      search: objectToUrl({ ...currentSearch, ...value })
    })
    await getData()
  }, [getData])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <Space align="baseline">
        <OrderFilter onChange={onOrderFilter} />
      </Space>
      {pageErrors.length > 0 ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
          />
        </div>
      ) : null}
      <TableWrapper>
        <Table
          columns={finalColumns}
          rowClassName={rowClassName}
          size="small"
          dataSource={dataSource}
          scroll={{ x: totalWidth, y: '72vh' }}
          pagination={false}
          loading={isDataLoading}
          onHeaderRow={(column: any) => ({
            style: {
              fontSize: column.key && column.key.substr(-3) === '-at' ? 9 : 13,
            },
          })}
          onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick) })}
        />
      </TableWrapper>
      {Boolean(totalRows) && <PaginationWrapper>
        <Pagination defaultCurrent={1} current={page} pageSize={10} total={totalRows} onChange={onPagination} showSizeChanger={false} />
      </PaginationWrapper>}
    </B2BLayout>
  )
}
